import Script from 'next/script'

interface BounceXProps{
  bouncexId: string
}
const BounceX: React.FC<BounceXProps> = ({ bouncexId }) => (
  <Script
    strategy="afterInteractive"
    data-testid="wunderkindCode"
    type="text/javascript"
    id="wkndCode"
    src={`${(typeof window !== 'undefined' && window?.location.protocol) || 'https:'}//tag.wknd.ai/${bouncexId}/i.js`}
  />
)

export default BounceX
